<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>Staffs</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  <div class="accordion">
                    <input id="toggle1" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle1">English Department</label>
                    <section>
                    <div class="team-wrapper">
                    
                    <div class="team">
                    <div class="team_member" v-for="eng in english" :key="eng.id">
                        <div class="team_img">
                        <img :src="eng.img" :alt="eng.Name">
                        </div>
                        <h3>{{eng.Name}}</h3>
                        <p class="role">{{eng.Subject}}</p>
                        <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{eng.Contact}}</p>
                        <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{eng.Email}}</p>
                        </div>
                        </div>
                    </div>               
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle2" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle2">Dzongkha Department</label>
                    <section>
                                <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="dzo in dzongkha" :key="dzo.id">
                                    <div class="team_img">
                                    <img :src="dzo.img" :alt="dzo.Name">
                                    </div>
                                    <h3>{{dzo.Name}}</h3>
                                    <p class="role">{{dzo.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{dzo.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{dzo.Email}}</p>
                                    </div>
                                    </div>
                                </div>               
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle3" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle3">ICT Department</label>
                    <section>
                               <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="it in ict" :key="it.id">
                                    <div class="team_img">
                                    <img :src="it.img" :alt="it.Name">
                                    </div>
                                    <h3>{{it.Name}}</h3>
                                    <p class="role">{{it.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{it.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{it.Email}}</p>
                                    </div>
                                    </div>
                                </div>                   
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle4" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle4">Mathematics Department</label>
                    <section>
                                <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="math in mathematics" :key="math.id">
                                    <div class="team_img">
                                    <img :src="math.img" :alt="math.Name">
                                    </div>
                                    <h3>{{math.Name}}</h3>
                                    <p class="role">{{math.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{math.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{math.Email}}</p>
                                    </div>
                                    </div>
                                </div>                
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle5" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle5">Physics Department</label>
                    <section>
                                 <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="phy in physics" :key="phy.id">
                                    <div class="team_img">
                                    <img :src="phy.img" :alt="phy.Name">
                                    </div>
                                    <h3>{{phy.Name}}</h3>
                                    <p class="role">{{phy.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{phy.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{phy.Email}}</p>
                                    </div>
                                    </div>
                                </div>                
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle6" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle6">Chemistry Department</label>
                    <section>
                               <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="che in chemistry" :key="che.id">
                                    <div class="team_img">
                                    <img :src="che.img" :alt="che.Name">
                                    </div>
                                    <h3>{{che.Name}}</h3>
                                    <p class="role">{{che.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{che.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{che.Email}}</p>
                                    </div>
                                    </div>
                                </div>                  
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle7" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle7">Biology Department</label>
                    <section>
                               <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="bio in biology" :key="bio.id">
                                    <div class="team_img">
                                    <img :src="bio.img" :alt="bio.Name">
                                    </div>
                                    <h3>{{bio.Name}}</h3>
                                    <p class="role">{{bio.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{bio.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{bio.Email}}</p>
                                    </div>
                                    </div>
                                </div>                  
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle8" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle8">History Department</label>
                    <section>
                                <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="his in history" :key="his.id">
                                    <div class="team_img">
                                    <img :src="his.img" :alt="his.Name">
                                    </div>
                                    <h3>{{his.Name}}</h3>
                                    <p class="role">{{his.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{his.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{his.Email}}</p>
                                    </div>
                                    </div>
                                </div>                 
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle9" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle9">Geography Department</label>
                    <section>
                                   <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="geo in geography" :key="geo.id">
                                    <div class="team_img">
                                    <img :src="geo.img" :alt="geo.Name">
                                    </div>
                                    <h3>{{geo.Name}}</h3>
                                    <p class="role">{{geo.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{geo.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{geo.Email}}</p>
                                    </div>
                                    </div>
                                </div>              
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle10" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle10">Economics Department</label>
                    <section>
                                <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="eco in economics" :key="eco.id">
                                    <div class="team_img">
                                    <img :src="eco.img" :alt="eco.Name">
                                    </div>
                                    <h3>{{eco.Name}}</h3>
                                    <p class="role">{{eco.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{eco.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{eco.Email}}</p>
                                    </div>
                                    </div>
                                </div>                 
                    </section>
                </div>
                <div class="accordion">
                    <input id="toggle11" type="radio" class="accordion-toggle" name="toggle" />
                    <label for="toggle11">Commerce Department</label>
                    <section>
                                <div class="team-wrapper">                    
                                <div class="team">
                                <div class="team_member" v-for="comm  in commerce" :key="comm.id">
                                    <div class="team_img">
                                    <img :src="comm.img" :alt="comm.Name">
                                    </div>
                                    <h3>{{comm.Name}}</h3>
                                    <p class="role">{{comm.Subject}}</p>
                                    <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> {{comm.Contact}}</p>
                                    <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> {{comm.Email}}</p>
                                    </div>
                                    </div>
                                </div>
                     </section>  
                    </div>
                </div>
   
   
              
            
          <div class="sidebar">
              <StaffsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import StaffsQuickLinks from "../../components/page/StaffsQuickLinks.vue";
import English from '@/assets/data/Staffs/English.json' 
import Dzongkha from '@/assets/data/Staffs/Dzongkha.json' 
import ICT from '@/assets/data/Staffs/ICT.json' 
import Mathematics from '@/assets/data/Staffs/Mathematics.json' 
import Chemistry from '@/assets/data/Staffs/Chemistry.json' 
import Biology from '@/assets/data/Staffs/Biology.json' 
import Physics from '@/assets/data/Staffs/Physics.json' 
import History from '@/assets/data/Staffs/History.json' 
import Geography from '@/assets/data/Staffs/Geography.json' 
import Economics from '@/assets/data/Staffs/Economics.json' 
import Commerce from '@/assets/data/Staffs/Commerce.json'
import Footer from "../../components/page/Footer.vue"; 



export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, StaffsQuickLinks, Footer },
    setup(){
        const title= ref('Teaching Staffs')
        return {title}
    },
    data(){
        return{
            english: English,
            dzongkha:Dzongkha,
            ict: ICT,
            mathematics: Mathematics,
            physics: Physics,
            chemistry: Chemistry,
            biology:Biology,
            history:History,
            geography:Geography,
            economics:Economics,
            commerce:Commerce,
        }
    }
    
  
}
</script>

<style scoped>


@import url("https://netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css");
.page .page-content h1{
    margin-bottom: 1rem;
}


.accordion{

  transform: translateZ(0);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.accordion > .accordion-toggle{
  position: absolute;
  opacity: 0;
  display: none;
}

.accordion > label{
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  border-top: 1px solid #ddd;
  background: #fff;
  cursor: pointer;
}

.accordion > label:after {
  content: '\f078';
  position: absolute;
  top: 0px;
  right: 20px;
  font-family: fontawesome;
  transform: rotate(90deg);
  transition: .3s transform;
}

.accordion > section{
  height: 0;
  transition: .3s all;
  overflow: hidden;
 
}
.accordion > .accordion-toggle:checked ~ label{
    background: var(--secondary);
    color: var(--pure);
    font-weight: 400;
}
.accordion > .accordion-toggle:checked ~ label:after{
 transform: rotate(0deg);
 
}

.accordion > .accordion-toggle:checked ~ section{
  height: auto;
  padding-bottom: 1rem;
}

.accordion > section p {  
    
  line-height: 1.5;
}
.team-wrapper{
  margin-top: 4rem;
  
}
.team{
  display: flex;
  justify-content: center;
  width: auto;
  text-align: center;
  flex-wrap: wrap;
}

.team .team_member{
  background: var(--secondary);
  margin: 2.5rem 0.5rem;  
  width: 100%;
  padding: 1rem;
  line-height: 1.2rem;
  color: #a7a2a2;  
  position: relative;
  box-sizing: border-box;
  
  
}

.team .team_member h3{
  color: var(--primary);
  font-size: 1.2rem;
  margin-top: 4.5rem
}
.team .team_member .contact, .mail{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.team .team_member .contact img{
    width: 1.7rem;
    padding-right: 0.5rem;
    filter: invert(1);
}
.team .team_member .mail img{
    width: 1.7rem;
    padding-right: 0.5rem;
    filter: invert(1);
}
.team .team_member .mail{
    font-size: 0.85rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: 1.3s;
    -moz-transition: 1.3s;
    transition: 1.3s;     
  
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    transition-timing-function: linear;
}
.team .team_member p.role{
  color: #ccc;
  margin: 12px 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}
.team .team_member p.mail{
    overflow:hidden;    
}
.team .team_member p.mail:hover{
    
    margin-left: -25px;
    z-index: 3;

}
.team .team_member .team_img{
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--primary);
}

.team .team_member .team_img img{
  width: 150px;
  height: 150px;
  padding: 5px;
}
@media (min-width:768px) {
    .team .team_member{
        width: 45%;
    }
}
@media (min-width:1100px) {
    .team .team_member{
        width: 30%;
    }
}
</style>