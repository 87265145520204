<template>
  <div class="blog-cards">
      <div class="blog-card">            
            <div class="cardImg">
                <img :src="post.blogCoverPhoto" >
            </div>
           <div class="info">
               <router-link target="_blank" class="link" :to="{name:'ViewBlog', params: {id:post.id} }">
               <h4>{{post.blogTitle}}</h4></router-link>           
               <h6>Posted on: <span>{{post.date.toDate().toLocaleString('eng-us',{dateStyle:"long"})}}</span></h6> 
            </div>     
  </div>
  </div>
</template>

<script>

export default {
    name: "SideBarblogCard",
    props: ["post"],
  
}
</script>

<style scoped>
.blog-cards{
    margin: 1rem 0;
}
.blog-card{
    display: flex;
    align-items: center;
    padding: 0.4rem 0;
    border-bottom: 1px solid #ebe5e5;
    
    }

.blog-card .cardImg img{
    width: 150px;
}
.blog-card .info{
    display: flex;    
    flex-direction: column;    
    margin-left: 1rem;
}
.blog-card .info h4{
    font-size: 1rem;
    font-weight: 300;
}
.blog-card .info h6{
    font-size: 12px;
    font-weight: 400;
  
}
.blog-card .info h6 span{
    color: #303030;
}
.blog-card .info .link{
    color: var(--secondary);
    text-decoration: none;
    
}
.blog-card .info .link:hover{
    color: var(--primary);
}

</style>