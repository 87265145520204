<template>
  <div class="tab">
              <ul>
                  <li class='heading'>Our Staffs</li>
                  <li class="one"><router-link :to = "{name: 'SchoolManagement'}">School Management</router-link></li>
                  <li class="two"><router-link :to = "{name: 'TeachingStaffs'}">Teaching Staffs</router-link></li>
                  <li class="three"><router-link :to = "{name: 'NonTeachingStaffs'}">Non-Teaching Staffs</router-link></li>
                  
              </ul>
          </div>
</template>

<script>
export default {

}
</script>

<style>

</style>