<template>
    <div class="tab">
        <p class="heading"> Recent Posts</p>      
    </div>

  <div class="recent-posts" v-if="documents">
      <SideBarBlogCard :post="post" v-for="(post,id) in documents.slice(0,5)" :key="id"/>
  </div>
  <div v-else class="posts">
      <Loading/>
  </div>
</template>

<script>
import getCollection from '../../composables/getCollection';
import SideBarBlogCard from "./SideBarBlogCard.vue";
import Loading from '../Loading.vue';
export default {
    name: "RecenePosts",
    components: { SideBarBlogCard, Loading },
    setup(){
        const {documents} = getCollection('blogPosts')

        return {documents}
    }
}
</script>

<style scoped>
.posts{
    display: flex;
    justify-content: center;
}
</style>